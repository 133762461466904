<template>
  <DashboardTemplate class="feedback">
    <Layout>
      <div
        class="text-center"
        v-if="typeof this.$route.params.from == 'undefined' && user.role == 0"
      >
        <h3>เรียน ท่านผู้ประกอบการ</h3>
        <p class="mg-b-0">
          เพื่อให้ EXIM Bank สามารถพัฒนาระบบ TERAK
          ให้ตอบสนองความต้องการได้ดีขึ้น
        </p>
        <p class="mg-t-0">
          ขอความร่วมมือผู้ประกอบการทำแบบประเมินความพึงพอใจในการใช้ระบบ TERAK
        </p>
        <h4 class="mg-b-0 font-weight-bold">ขอกราบขอบพระคุณ,</h4>
        <h4 class="mg-t-0 font-weight-bold">
          ธนาคารเพื่อการส่งออกและนำเข้าแห่งประเทศไทย (EXIM Bank)
        </h4>
        <el-button
          v-if="typeof this.$route.params.from == 'undefined'"
          type="default"
          class="wid180 mg-r-3"
          @click="filLater"
          :loading="loading"
          >ไว้คราวหน้า</el-button
        >
        <el-divider></el-divider>
      </div>
      <h2 class="text-center mg-t-1 text-primary">
        ประเมินความพึงพอใจในการใช้งานระบบประเมิน TERAK
      </h2>
      <el-form ref="form" :model="form" label-position="top" class="mg-t-1">
        <el-row
          :gutter="20"
          v-for="(item, index) in rates"
          :key="index"
          class="rate-item mg-b-5"
        >
          <el-col :lg="16">
            <p class="mg-t-0">{{ item.name }}</p></el-col
          >
          <el-col :lg="8"
            ><el-rate
              v-model="item.value"
              class="text-center"
              :disabled="checkComplacency"
            ></el-rate
          ></el-col>
        </el-row>
        <div v-if="checkComplacency">
          <p v-if="form.suggestion">ข้อเสนอแนะเพิ่มเติม</p>
          <div>
            {{ form.suggestion }}
          </div>
        </div>
        <el-form-item label="ข้อเสนอแนะเพิ่มเติม" v-else>
          <el-input
            type="textarea"
            v-model="form.suggestion"
            rows="4"
          ></el-input>
        </el-form-item>
      </el-form>
      <div class="is-flex js-center ai-center mg-t-1" v-if="!checkComplacency">
        <div>
          <el-button
            type="primary"
            class="wid180"
            @click="saveEditdata"
            :loading="loading"
            >ส่งแบบประเมิน</el-button
          >
        </div>
      </div>
    </Layout>
  </DashboardTemplate>
</template>

<script>
import DashboardTemplate from "@/template/DashboardTemplate";
import { HTTP } from "@/service/axios";

import Layout from "@/template/LayoutDashboardTemplate";
export default {
  components: {
    DashboardTemplate,
    Layout
  },
  data() {
    return {
      loading: false,
      rates: [
        {
          name: "1. การสมัครสมาชิกเพื่อทำการประเมินฯ มีความสะดวกง่ายและรวดเร็ว",
          value: null
        },
        {
          name: "2. เกณฑ์การประเมินฯ มีความครอบคลุมทุกแง่มุมธุรกิจ",
          value: null
        },
        {
          name: "3. เนื้อหาของเกณฑ์การประเมินฯ ง่ายต่อความเข้าใจ",
          value: null
        },
        {
          name: "4. ระบบการประเมินฯ มีความสะดวก ง่าย และใช้เวลาประเมินไม่นาน",
          value: null
        },
        {
          name: "5. การแสดงผลประเมินเข้าใจง่าย สะท้อนให้เห็นภาพธุรกิจชัดเจน",
          value: null
        },
        {
          name:
            "6. คำแนะนำและรายงานคำแนะนำเพื่อปรับปรุงธุรกิจเข้าใจง่าย และสามารถนำไปปฏิบัติได้จริง",
          value: null
        }
      ],
      form: {
        suggestion: ""
      },
      checkComplacency: false
    };
  },
  async created() {
    await this.checkAssessment();
    await this.getData();
  },
  methods: {
    async filLater() {
      let res = await HTTP.get("skip");
      let url;
      if (this.user.recommendStatus == 2) {
        url = "/factor";
      } else {
        url = "/result";
      }
      this.$router.push(url).catch(err => {});
    },
    async checkAssessment ()
    {
      HTTP.defaults.headers.common["X-CSRF-TOKEN"] = this.$csrfToken;
      HTTP.defaults.headers.common.Authorization = `Bearer ${this.token}`;
      let res = await HTTP.get(`check/conplacency`);
      if (res.data.success) {
        this.checkComplacency = res.data.obj;
      }
    },
    async getData ()
    {
      HTTP.defaults.headers.common["X-CSRF-TOKEN"] = this.$csrfToken;
      HTTP.defaults.headers.common.Authorization = `Bearer ${this.token}`;
      let res = await HTTP.get(`/complcaency/${this.user.id}`);
      //console.log("res", res);
      if (res.data.success) {
        this.rates[0].value = res.data.obj.one;
        this.rates[1].value = res.data.obj.two;
        this.rates[2].value = res.data.obj.three;
        this.rates[3].value = res.data.obj.four;
        this.rates[4].value = res.data.obj.five;
        this.rates[5].value = res.data.obj.six;
        this.form.suggestion = res.data.obj.description;
      }
    },
    async saveEditdata() {
      let check = false;
      this.rates.forEach(data => {
        if (!data.value) {
          check = true;
        }
      });
      if (check) {
        this.$message.error("กรุณาประเมินให้คะแนนความพึงพอใจทุกข้อ");
        return false;
      }

      let obj = {
        one: this.rates[0].value,
        two: this.rates[1].value,
        three: this.rates[2].value,
        four: this.rates[3].value,
        five: this.rates[4].value,
        six: this.rates[5].value,
        description: this.form.suggestion
      };
      //console.log(obj);
      HTTP.defaults.headers.common["X-CSRF-TOKEN"] = this.$csrfToken;
      HTTP.defaults.headers.common.Authorization = `Bearer ${this.token}`;
      let res = await HTTP.post(`/fill/in/conplacency`, obj);
      if (res.data.success) {
        this.$message.success("ขอบคุณสำหรับความคิดเห็นของท่าน");
        this.$router.push("/result");
      }
    }
  }
};
</script>

<style lang="scss" scoped>
</style>